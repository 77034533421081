<template>
  <v-sheet class="promotion" style="height: calc(100vh - 140px)">
    <v-card-text class="px-0 custom_field_height">
      <v-row>

        <v-col md="1" class="py-0 pr-0 ml-2 " v-if="status.length">
          <PromotionHeader class="mt-6" moduleType="purchaseorder" :dataLoading="dataLoading" :stat="selectstatus"
            :status-list="status" allkey="all_purchase" :all="all" :active="active" :Inactive="Inactive" :draft="draft"
            countkey="status_count" v-on:saveAddress="updateAddressPerson"></PromotionHeader>
          <!-- <label for="segment_name" class="custom-form-label" style="font-weight: 600"
              >Status</label
            >
            <SelectInput
              hide-details
              :disabled="pageLoading"
              :loading="pageLoading"
              :items="status"
              id="segment"
              item-text="name"
              item-value="id"
              placeholder="Select Status"
            ></SelectInput> -->
        </v-col>
        <v-col md="3" class="py-0 pr-0" style="max-width:340px;">
          <label for="segment_name" class="custom-form-label" style="font-weight: 600;">Filter By Date Range</label>
          <DateRangePicker hide-details class="mt-3" v-model="range" :disabled="pageLoading" :loading="pageLoading"
            id="item-sale-duration" placeholder="Date Range"></DateRangePicker>
        </v-col>
        <v-col class="py-0 pr-0" style="max-width: 180px; width: 180px">
          <label for="segment_name" class="custom-form-label" style="font-weight: 600">Filter by Month</label>
          <AutoCompleteInput class="mt-3" hide-details :disabled="pageLoading" :loading="pageLoading" :items="months"
            id="segment" item-text="name" item-value="value" v-model="search_month" placeholder="Select Month">
          </AutoCompleteInput>
        </v-col>
        <!-- <v-col md="1" class="py-0 pr-0">
            <label for="category_type" class="custom-form-label" style="font-weight: 600"
              >Filter by Category</label
            >
            <AutoCompleteInput
              hide-details
              class="mt-3"
              :disabled="pageLoading"
              :loading="pageLoading"
              :items="['Members', 'Premium']"
              id="category"
              item-text="name"
              item-value="id"
              placeholder="Select Type"
            ></AutoCompleteInput>
          </v-col> -->
        <v-col md="3" class="mt-5 px-0">
          <v-flex>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn style="max-width:10px;" color="blue darken-4" v-on="on" v-bind="attrs" depressed tile
                  @click="searchPromotions" class="ml-2 white--text me-1 px-1"> <v-icon>mdi-magnify</v-icon></v-btn>
              </template>
              <span>Filter</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="red darken-4" v-on="on" v-bind="attrs" depressed tile v-on:click="reset"
                  class="ml-2 white--text me-1">Reset </v-btn>
              </template>
              <span>Reset </span>
            </v-tooltip>
            <!-- <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="blue darken-4"
                    v-on="on"
                    v-bind="attrs"
                    depressed
                    tile
                    class="ml-2 white--text"
                    ><v-icon>mdi-file-excel</v-icon></v-btn
                  >
                </template>
                <span>Export</span>
              </v-tooltip> -->
          </v-flex>
        </v-col>
        <v-col class="mt-5 justify-content-end ps-0">
          <v-flex class="d-flex justify-content-end">
            <v-menu offset-y left transition="slide-y-transition" :close-on-content-click="false">
              <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip left>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn color="blue darken-4" depressed tile v-bind="attrs" v-on="{ ...tooltip, ...menu }"
                      class="white--text">
                      <v-icon>edit_note</v-icon>
                    </v-btn>
                  </template>
                  <span>Columns arrangement and visibility</span>
                </v-tooltip>
              </template>
              <v-list nav dense>
                <v-list-item draggable @dragstart="dragstart(index)" @dragover="dragover(index)" @dragend="dragEnd"
                  v-for="(item, index) in defaultColDefs" :key="item.field + '_' + index"
                  class="flex-column column-arrangement-list-item cursor-move">
                  <div class="d-flex flex-row">
                    <template v-if="!item.checkbox || Number(item.checkbox) == 0">
                      <v-checkbox :ripple="false" hide-details color="#0d47a1" v-model="defaultColShow"
                        v-bind:value="item.field" :disabled="Number(item.fixed) || pageLoading"
                        v-on:change="updateVisible()" class="m-0"></v-checkbox>
                      <v-list-item-title>{{ item.headerName }}</v-list-item-title>
                      <v-icon>drag_indicator</v-icon>
                    </template>
                  </div>

                  <v-divider v-if="index < customThead.length - 1" class="mt-1 mb-0" style="width: 100%"></v-divider>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn :disabled="pageLoading" color="blue darken-4" depressed tile class="ml-2 white--text"
              :to="{ name: 'promotion-create', query: { t: new Date().getTime() } }">
              New
            </v-btn>
            <v-btn v-if="selectstatus == 'Active' && sequence != 1" :disabled="pageLoading" color="blue darken-4" depressed
              tile class="ml-2 white--text" @click="Sequence()">
              Sequence
            </v-btn>
            <v-btn v-if="selectstatus == 'Active' && sequence == 1" :disabled="pageLoading" color="blue darken-4" depressed
              tile class="ml-2 white--text" @click="savesOrder()">
              Save
            </v-btn>
            <v-btn v-if="selectstatus == 'Active' && sequence == 1" class="ml-2" :disabled="pageLoading" depressed tile
              v-on:click="gochange()">
              Cancel
            </v-btn>
          </v-flex>
        </v-col>
      </v-row>
    </v-card-text>
    <SearchCriteria v-if="listingSearch" :search="listingSearch"
      search-string="Promotion #, Full Name, Email, Phone No. and Address" v-on:close-search="resetSearch">
    </SearchCriteria>
    <v-simple-table fixed-header class="bt-table table_height">
      <template v-slot:default>
        <thead>
          <tr>
            <th v-for="heading in customThead" :key="heading.title" style="background-color: #f5f5f5">
              <div v-if="heading.status">{{ heading.headerName }}</div>
            </th>
          </tr>
        </thead>
        <Draggable tag="tbody" v-model="promotions" class="draggable-group" handle=".draggable-drag-icon"
          v-on:change="updateItemOrder($event)">
          <template v-if="promotions.length && promotions.length > 0">

            <tr v-for="(row, bkey) in promotions" :key="bkey" :class="bkey % 2 === 0 && 'blue lighten-5'"
              class="promotion-listing-tr">
              <td v-for="(th, index) in customThead" :key="'key_' + index" :width="th.field == 'action' ? '50px' : ''">
                <div v-if="th.field === 'title' && th.status">
                  <template>
                    {{ row.title }}
                  </template>

                  <!-- {{ row[getColValue(th.title)]?.value }} -->
                </div>

                <div v-else-if="th.field === 'action' && th.status">
                  <v-tooltip top content-class="custom-top-tooltip" v-if="selectstatus == 'Active' && sequence == 1">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="draggable-drag-icon cursor-move" color="blue darken-4">mdi-drag</v-icon>


                    </template>
                    <span>Order</span>
                  </v-tooltip>
                  <v-tooltip top content-class="custom-top-tooltip" v-if="row && row.is_active == 2">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn depressed fab dark x-small class="mx-2"
                        color="green" v-bind="attrs" v-on="on" v-on:click.stop.prevent="routeToUpdate(row.id)">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn depressed fab dark x-small color="red" v-bind="attrs" v-on="on"
                        v-on:click.stop.prevent="deleteConfirm(row)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </div>
                <!-- <div v-else-if="getColValue(th.title) === 'title' && th.status">
                    <v-chip class="ma-2" color="#0D479F" text-color="white">
                      <v-icon medium class="me-1">mdi-account-circle</v-icon>
                      <ValueTemplate
                        v-model="row[getColValue(th.title)].value"
                        :title="row[getColValue(th.title)].key"
                      ></ValueTemplate>
                      {{ row[getColValue(th.title)]?.value }}
                    </v-chip>
                  </div> -->
                <div v-else-if="th.field === 'images' && th.status">
                  <template v-if="row?.transaction_documents_primary?.file">
                    <v-avatar>
                      <img :src="row?.transaction_documents_primary?.file?.url" alt="John" />
                    </v-avatar>
                  </template>
                  <template v-else>No Image</template>

                </div>
                <div v-else-if="th.field === 'category' && th.status">

                  <v-chip outlined label :color="row.category == 'members' ? 'green' : 'red'">
                    <ValueTemplate style="text-transform: uppercase;" v-model="row.category" :title="row.category">
                    </ValueTemplate>
                  </v-chip>

                  <!-- {{ row[getColValue(th.title)]?.value }} -->
                </div>
                <!-- <div v-else-if="getColValue(th.title) === 'description' && th.status">
                    {{ addElipses(text, 20).text }} &nbsp;
                    <span
                      v-if="addElipses(text, 20).readmore"
                      style="color: #24326d; cursor: pointer; font-size: 12px"
                      @click="dialog = true"
                      >Read More</span
                    >
                  </div> -->
                <div v-else-if="th.field === 'description' && th.status">
                  <div v-if="row.long_description" class="text-truncate" style="width: 250px">{{ row.long_description }}
                  </div>
                  <span v-if="row && row.long_description && row.long_description.length > 20"
                    style="color: #24326d; cursor: pointer; font-size: 12px"
                    @click="remarkPop(row.long_description, row?.transaction_documents_primary?.file?.url)">Read
                    More</span>
                  <div v-if="!row.long_description" class="text-truncate" style="width: 250px">No Description</div>

                </div>

                <div v-else-if="th.field === 'status' && th.status">
                  <template v-if="row.is_active == 3">
                    <v-chip outlined label :color="row.is_active == 'members' ? 'green' : 'red'">
                      Pending
                    </v-chip>
                  </template>
                  <template v-if="row.is_active == 2">
                    <v-chip outlined label :color="row.is_active == 'members' ? 'green' : 'cyan'">
                      Draft
                    </v-chip>
                  </template>
                  <template v-if="row.is_active == 1 || row.is_active == 0">
                    <v-switch v-model="row.is_active" class="mt-0"
                      v-on:click.prevent.stop="() => activeInactive(row.is_active, row?.id)" color="green"></v-switch>
                  </template>


                </div>
                <div v-else-if="th.field === 'created_at' && th.status">

                  <div class="text-sm mt-1">
                    <div>
                      <v-icon start size="16">mdi-calendar</v-icon>
                      {{ formatedate(row.added_at) }}
                      <VTooltip activator="parent"> Created date </VTooltip>
                    </div>
                  </div>
                  <div class="text-sm mt-1">
                    <div>
                      <v-icon start size="16">mdi-clock</v-icon>
                      {{ formateTime(row.added_at) }}
                      <VTooltip activator="parent"> Created Time </VTooltip>
                    </div>
                  </div>

                </div>

                <div v-else-if="th.field === 'start_date' && th.status">
                  <template v-if="row.start_date"> {{ formatedate(row.start_date) }}</template>
                  <template v-else>No Start Date</template>

                  <!-- {{ row[getColValue(th.title)]?.value }} -->
                </div>

                <div v-else-if="th.field === 'expiry_date' && th.status">
                  <template v-if="row.end_date">{{ formatedate(row.end_date) }}</template>
                  <template v-else>No Expiry Date</template>

                  <!-- {{ row[getColValue(th.title)]?.value }} -->
                </div>

                <div v-else-if="th.field === 'publish_date' && th.status">
                  <template v-if="row.publish_date">{{ formatedate(row.publish_date) }}</template>
                  <template v-else>No Publish Date</template>


                  <!-- {{ row[getColValue(th.title)]?.value }} -->
                </div>
              </td>
            </tr>

          </template>
          <template v-else>
            <tr>
              <td colspan="10">
                <p class="m-0 text-center">
                  <img width="30" :src="$assetURL('media/error/empty.png')" class="row-not-found-image mr-4" />
                  Uhh... There are no promotion at the moment.
                </p>
              </td>
            </tr>
          </template>
        </Draggable>


      </template>
    </v-simple-table>


    <Dialog :dialog="dialog" :dialogWidth="900">
      <template v-slot:title>Description</template>
      <template v-slot:body>
        <v-row class="delete-dialog">
          <v-col md="2" class="py-0 text-right my-auto">
            <span class="svg-icon svg-icon-lg delete-confirm-icon">
              <img v-if="images" width="80" height="80" :src=images />
              <img v-else width="80" height="80"
                src="https://bthrust-fsm.s3.ap-southeast-1.amazonaws.com/files/image/png/OFVhotIVFLGfIcidqwYD9YQxR3edOtSq7cZaTji6.png" />


            </span>
          </v-col>
          <v-col md="10" class="py-0 my-auto">
            <p class="btx-p m-0"><b class="red--text text--lighten-1"></b>{{ text }}</p>
          </v-col>
        </v-row>
      </template>
      <template v-slot:action>
        <v-btn depressed tile @click="dialog = false"> Close </v-btn>
      </template>
    </Dialog>

    <OrderTemplate v-model="order" :customer-id="promotionId" :dialog="orderDialog" v-on:close="orderDialog = false"
      v-on:save="saveOrder()" v-on:collect:voucher="getAvailableVouchers" v-on:claim:voucher="getCollectedVouchers">
    </OrderTemplate>
    <CollectTemplate :dialog="collectDialog" :order-id="cOrderId" :order-code="cOrderCode" :vouchers="cVouchers"
      v-on:close="vCollected()" v-on:collected="vCollected()"></CollectTemplate>
    <RedeemTemplate :dialog="redeemDialog" :order-id="rOrderId" :order-code="rOrderCode" :vouchers="rVouchers"
      v-on:close="vRedeemed()" v-on:redeemed="vRedeemed()"></RedeemTemplate>
    <DeleteTemplate type="promotion" :delete-dialog="deleteDialog" :delete-endpoint="deleteEndpoint"
      v-on:close="deleteDialog = false" v-on:delete:success="getPromotions()">
    </DeleteTemplate>
    <SearchTemplate :dialog="allCollectedDialog" v-on:close="allCollectedDialog = false"></SearchTemplate>
    <Dialog dense :dialog="whatsappPromotional" v-if="whatsappPromotional">
      <template v-slot:title> Whatsapp Promotional </template>
      <template v-slot:body>
        <div class="mx-6" fluid>
          <v-row class="mx-6">
            <v-col md="2">
              <label for="item-file-upload" class="btx-label mt-2 required">Template</label>
            </v-col>
            <v-col md="8">
              <SelectInput hide-details hideTopMargin :loading="pageLoading" :disabled="pageLoading" item-text="text"
                dense item-value="value" :items.sync="templateList" v-model="whatsapp.template"></SelectInput>
            </v-col>
          </v-row>
          <v-row class="mx-6">
            <v-col md="2">
              <label for="item-file-upload" class="btx-label mt-2 required">File</label>
            </v-col>
            <v-col md="8">
              <v-file-input v-model="whatsapp.files" ref="fileInput" hide-details id="item-file-upload"
                label="Upload Images" outlined dense></v-file-input>
            </v-col>
          </v-row>
        </div>
      </template>
      <template v-slot:action>
        <v-btn :disabled="pageLoading" :loading="pageLoading" class="white--text mr-2" depressed color="blue darken-4"
          tile @click="whatsappPromotional = false">
          Send
        </v-btn>
        <v-btn :disabled="pageLoading" depressed tile v-on:click="whatsappPromotional = false">
          Cancel
        </v-btn>
      </template>
    </Dialog>
  </v-sheet>
</template>
<script>
import Dialog from "@/view/components/Dialog";
import SearchCriteria from "@/view/components/SearchCriteria";
import { GET_PROMOTION_LIST, GET_TEMPLATE_LIST } from "@/core/lib/customer.lib";
import { UPDATE_PROMOTION_SEQUENCE } from "@/core/lib/promotion.lib";

import { SET_ERROR } from "@/core/services/store/common.module";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import OrderTemplate from "@/view/components/OrderTemplate";
import CollectTemplate from "@/view/components/CollectVoucherTemplate";
import ValueTemplate from "@/view/components/ValueTemplate";
import RedeemTemplate from "@/view/components/RedeemVoucherTemplate";
import SearchTemplate from "@/view/components/SearchAllVouchers";
import DateRangePicker from "@/view/components/DateRangePicker";
import PromotionHeader from "@/view/components/PromotionHeader.vue";
import Draggable from "vuedraggable";
import { SET_MESSAGE } from "@/core/services/store/common.module";
import { PATCH } from "@/core/services/store/request.module";
import { SearchEventBus } from "@/core/lib/search.lib";

import { filter, map } from "lodash";

import { POST } from "@/core/services/store/request.module";

import {
  SET_SEARCH_TITLE,
  SET_SEARCH_MODEL,
  SET_SEARCH_BAR,
  BEFORE_DESTROY,
} from "@/core/services/store/listing.module";
import { mapGetters } from "vuex";
import { format } from "date-fns";
import { GET_AVAILABLE_VOUCHERS, GET_COLLECTED_VOUCHERS } from "@/core/lib/common.lib";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";

import SelectInput from "@/view/components/SelectInput.vue";
import moment from "moment-timezone";


export default {
  name: "promotion-listing",
  title: "Listing Promotion",
  props: {
    status: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      selectstatus: "null",
      searchParam: null,
      images: null,
      all: 0,
      defaultColDefs: [],
      customThead: [],
      active: 0,
      Inactive: 0,
      searchEnabled: null,
      list: [],
      range: null,
      search_month: null,
      months: [
        {
          name: "January",
          value: 1,
        },
        {
          name: "February",
          value: 2,
        },
        {
          name: "March",
          value: 3,
        },
        {
          name: "April",
          value: 4,
        },
        {
          name: "May",
          value: 5,
        },
        {
          name: "June",
          value: 6,
        },
        {
          name: "July",
          value: 7,
        },
        {
          name: "August",
          value: 8,
        },
        {
          name: "September",
          value: 9,
        },
        {
          name: "October",
          value: 10,
        },
        {
          name: "November",
          value: 11,
        },
        {
          name: "December",
          value: 12,
        },
      ],
      sequence: null,
      text: "Also called a company description, your business description summarizes what your company does, its purpose, and what makes it unique. It's a critical component of your business plan and immediately follows your executive summary",
      dialog: false,
      dataLoading: false,
      promotionTableHeading: [
        { title: "ACTION", status: true },
        { title: "IMAGES", status: true },
        { title: "STATUS", status: true },
        { title: "TYPE", status: true },
        { title: "TITLE", status: true },
        { title: "DESCRIPTION", status: true },
        { title: "START DATE", status: true },
        { title: "EXPIRY DATE", status: true },
        { title: "PUBLISH DATE", status: true },
        { title: "CREATED AT", status: true },
      ],
      promotionTableData: [
        {
          code: "XMH36",
          action: { value: "", key: "ACTION" },
          title: { value: "BUSINESS", key: "TITLE" },
          type: { value: "MEMBERS", key: "TYPE" },
          description: { value: "DESCRIPTION", key: "DESCRIPTION" },
          start_date: { value: "8/08/2023", key: "START DATE" },
          expiry_date: { value: "28/08/2023", key: "END DATE" },
          publish_date: { value: "28/08/2023", key: "PUBLISH DATE" },
          images: {
            value: "https://cdn.pixabay.com/photo/2023/07/04/18/10/wreath-8106795_640.png",
            key: "IMAGES",
          },
        },
        {
          code: "XMQ96",
          action: { value: "", key: "ACTION" },
          title: { value: "CAP STOCKER", key: "TITLE" },
          type: { value: "PREMIUM", key: "TYPE" },
          description: { value: "DESCRIPTION", key: "DESCRIPTION" },
          start_date: { value: "8/08/2023", key: "START DATE" },
          expiry_date: { value: "28/08/2023", key: "END DATE" },
          publish_date: { value: "28/08/2023", key: "PUBLISH DATE" },
          images: {
            value:
              "https://plus.unsplash.com/premium_photo-1661953124283-76d0a8436b87?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8cmVzdGF1cmFudHxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60",
            key: "IMAGES",
          },
        },
      ],
      dragStartIndex: null,
      dragOverIndex: null,
      templateList: [{ text: "First", value: "first" }],
      template: "first",
      whatsapp: {
        files: [],
        template: "first",
      },
      filterType: "all",
      filterDateRange: null,
      speedDial: [],
      draft: null,
      whatsappPromotional: false,
      promotions: [],
      currentPage: 1,
      pageLimit: 1,
      showingFrom: 0,
      showingTo: 0,
      totalRows: 0,
      pageLoading: true,
      deleteText: null,
      deleteEndpoint: null,
      deleteDialog: false,
      promotionId: null,
      orderDialog: false,
      rOrderId: 0,
      rOrderCode: null,
      rLoading: false,
      rVouchers: [],
      redeemDialog: false,
      cOrderId: 0,
      cOrderCode: null,
      cLoading: false,
      cVouchers: [],
      collectDialog: false,
      allCollectedDialog: false,
      order: {
        id: null,
        date: format(new Date(), "yyyy-MM-dd"),
        time: format(new Date(), "HH:mm"),
        bill_no: null,
        amount: null,
        voucher: "none",
      },
    };
  },
  watch: {
    tableHeading: {
      handler: function (newval) {
        console.log(newval);
      },
      deep: true,
    },
    currentPage() {
      this.getPromotions();
    },
    listingSearch() {
      this.getPromotions();
    },
    filterDateRange() {
      if (this.filterDateRange && this.filterDateRange.length > 1) {
        this.filterType = "all";
        this.getPromotions();
      }
    },
  },
  methods: {
    updateVisible() {
      const _this = this;

      for (let index = 0; index < _this.defaultColDefs.length; index++) {
        const element = _this.defaultColDefs[index];
        _this.defaultColDefs[index].visible = 0;
        if (_this.defaultColShow.includes(element.field)) {
          _this.defaultColDefs[index].visible = 1;
        }
      }
      // console.log(this.defaultColDefs);

      _this.$nextTick(() => {
        _this.$store
          .dispatch(POST, {
            url: "group",
            data: { tables: this.defaultColDefs },
          })
          .then((data) => {
            this.customThead = data;
            // console.log(data)
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.getPromotions();
          });
      });
    },
    formateTime(param) {
      return moment(param).format("hh:mm A");
    },
    activeInactive(data, id) {
      const _this = this;
      console.log(data);
      _this.$store
        .dispatch(PATCH, {
          url: `single-promotion`,
          data: {
            id: id,
            status: Number(data),
          },
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.getPromotions();
        });
    },
    reset() {
      this.search_month = null;
      this.range = null;
      this.getPromotions();
    },

    updateItemOrder(e) {
      this.gfgdg = e
    },
    async savesOrder() {
      const _this = this;
      _this.list = _this.promotions
      const result = _this.list.map((item) => {
        return {
          id: item.id,

        };
      });
      console.log(result, '_this.list')
      try {
        _this.pageLoading = true;

        const promotion = await UPDATE_PROMOTION_SEQUENCE(result);
        console.log(promotion)
        _this.$router.replace({
          name: "promotion",
          query: { t: new Date().getTime() },
        });
        _this.$store.commit(SET_MESSAGE, [
          { model: true, message: "Promotion Sequence Updated Successfully." },
        ]);

      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        _this.pageLoading = false;
        this.sequence = 0;

        this.getPromotions();
      }

      console.log(this.list, 'list')
    },
    gochange() {
      this.sequence = 0;

    },
    Sequence() {
      this.sequence = 1;

    },
    updateAddressPerson(param) {
      this.selectstatus = param;
      this.sequence = 0;
      this.getPromotions();
    },
    remarkPop(reason, image) {
      this.text = reason;
      this.images = image;
      this.dialog = true
    },
    formatedate(param) {
      return moment(param).format("DD/MM/YYYY");
    },
    getColValue(val) {
      if (val.includes(" ")) {
        return val.split(" ").join("_").toLowerCase();
      } else {
        return val.toLowerCase();
      }
    },
    dragstart(index) {
      this.dragStartIndex = index;
    },
    dragover(index) {
      this.dragOverIndex = index;
    },
    dragEnd() {
      const dragStartElement = this.promotionTableHeading[this.dragStartIndex];
      this.promotionTableHeading.splice(this.dragStartIndex, 1);
      this.promotionTableHeading.splice(this.dragOverIndex, 0, dragStartElement);
    },
    drastart(index) {
      this.dragging_element_index = index;
    },
    addElipses(text, words) {
      if (text.length > 5) {
        if (words === 0) {
          return { text: text.slice(0, text.length), readmore: false };
        } else {
          return { text: text.slice(0, words) + "...", readmore: true };
        }
      } else {
        return { text: text.slice(0, text.length), readmore: false };
      }
    },
    getAllCollectedVouchers() { },
    // changeDatePicker() {
    //   if (this.filterDateRange[0] && this.filterDateRange[1]) {
    //     this.getPromotions()
    //   }
    // },
    filterTypeChange() {
      this.filterDateRange = null;
      // this.getPromotions();
    },
    saveOrder() {
      this.promotionId = null;
      this.order = {
        id: null,
        date: format(new Date(), "yyyy-MM-dd"),
        time: format(new Date(), "HH:mm"),
        bill_no: null,
        amount: null,
        voucher: "none",
      };
    },
    addOrder({ id }) {
      this.promotionId = id;
      this.orderDialog = true;
    },
    vCollected() {
      this.cOrderId = 0;
      this.cOrderCode = null;
      this.cVouchers = [];
      this.collectDialog = false;
      this.saveOrder();
    },
    vRedeemed() {
      this.rOrderId = 0;
      this.rOrderCode = null;
      this.rVouchers = [];
      this.redeemDialog = false;
      this.saveOrder();
    },
    async getAvailableVouchers(orderId, code) {
      try {
        this.cOrderId = orderId;
        this.cOrderCode = code;
        this.cLoading = true;
        this.cVouchers = await GET_AVAILABLE_VOUCHERS(orderId);
        this.collectDialog = true;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.cLoading = false;
      }
    },
    async getCollectedVouchers(orderId, code) {
      try {
        this.rOrderId = orderId || 0;
        this.rOrderCode = code || null;
        this.rLoading = true;
        this.rVouchers = await GET_COLLECTED_VOUCHERS(this.promotionId);
        this.redeemDialog = true;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.rLoading = false;
      }
    },
    routeToUpdate(id) {
      this.$router.push({
        name: "promotion-update",
        params: { id },
        query: { t: new Date().getTime() },
      });
    },
    routeToDetail(id) {
      this.$router.push({
        name: "promotion-detail",
        params: { id },
        query: { t: new Date().getTime() },
      });
    },
    deleteConfirm({ code, id }) {
      this.deleteText = code;
      this.deleteEndpoint = `delete-promotion/${id}`;
      this.deleteDialog = true;
    },
    searchPromotions() {
      this.getPromotions();

    },
    async getPromotions(search = null) {
      try {
        let form = {
          current_page: this.currentPage,
          search: search,
          selectstatus: this.selectstatus,
          filter_type: this.filterType,
          search_month: this.search_month,
        };
        if (this.range) {
          const dateRange = {
            start_date: this.range[0],
            end_date: this.range[1],
          };
          form = { ...form, ...dateRange };
        }
        this.pageLoading = true;
        const { data, totalPages, showingFrom, showingTo, totalRows, month, lastMonth, total, active, Inactive, theads, draft } = await GET_PROMOTION_LIST(form);
        this.promotions = data;
        this.pageLimit = totalPages;
        this.showingFrom = showingFrom;
        this.showingTo = showingTo;
        this.totalRows = totalRows;
        this.month = month;
        this.lastMonth = lastMonth;
        this.all = total;
        this.active = active;
        this.draft = draft;
        this.Inactive = Inactive;
        let thead = theads;
        this.defaultColDefs = thead;
        this.customThead = [];
        for (let index = 0; index < thead.length; index++) {
          let element = thead[index];
          if (element.visible == 1) {
            this.customThead.push(element);
          }
        }

        let t_out = filter(thead, (row) => Number(row.visible) == 1);
        this.defaultColShow = map(t_out, "field");

        console.log(this.customThead);
        console.log(this.all, 'total')

        this.$emit("saveAddress", totalRows);
        this.$emit("month", month);
        this.$emit("lastMonth", lastMonth);
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
    resetSearch() {
      this.$router
        .replace({
          query: {
            t: new Date().getTime(),
          },
        })
        .then(() => {
          this.$store.dispatch(SET_SEARCH_MODEL, null);
        });
    },
    async getTemplateListing() {
      try {
        this.pageLoading = true;
        console.log("getTemplateListing");
        const data = await GET_TEMPLATE_LIST();
        console.log("data", data);
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
  },
  async mounted() {
    let _this = this;
    SearchEventBus.$on("start-search", (argument) => {
      _this.searchEnabled = false;
      _this.searchParam = argument;
      if (_this.searchParam) {
        _this.searchEnabled = true;
      }
      this.getPromotions(argument);
    });
    SearchEventBus.$emit("search-template", true);

    this.getPromotions();
    this.sequence = 0;
    this.$store.dispatch(SET_SEARCH_BAR, true);
    this.$store.dispatch(SET_SEARCH_TITLE, "Promotion");
  },
  beforeDestroy() {
    this.$store.dispatch(BEFORE_DESTROY);
  },
  components: {
    DeleteTemplate,
    OrderTemplate,
    CollectTemplate,
    RedeemTemplate,
    SearchTemplate,
    SearchCriteria,
    SelectInput,
    Dialog,
    DateRangePicker,
    ValueTemplate,
    PromotionHeader,
    Draggable,
    AutoCompleteInput
  },
  computed: {
    ...mapGetters(["listingSearch"]),
    modifiedTableHeading() {
      const _tableHadings = this.promotionTableHeading.filter((heading) => heading.status);
      return _tableHadings;
    },

  },
};
</script>
  
<style>
.table_height .v-data-table__wrapper {
  height: calc(100vh - 360px);
}

.promotion-listing-tr:nth-child(even):hover {
  box-shadow: 0px 0px 10px #666666 !important;
  -webkit-box-shadow: 0px 0px 10px #666666 !important;
  -moz-box-shadow: 0px 0px 10px #666666 !important;
  background-color: #e3f2fd !important;
  position: relative;
  z-index: 5;
}

.promotion-listing-tr:nth-child(odd):hover {
  box-shadow: 0px 0px 10px #666666 !important;
  -webkit-box-shadow: 0px 0px 10px #666666 !important;
  -moz-box-shadow: 0px 0px 10px #666666 !important;
}

.custom_field_height v-input__control {
  max-height: 34px;
  height: 34px;
}

.custom_field_height .v-select__selection {
  max-width: 84%;
}
</style>
  