<template>
  <v-sheet class="promotion" style="height: calc(100vh - 140px)">
    <v-row>
      <v-col md="4" class="pr-0">
        <v-card class="mx-auto" style="border: 0.5px solid #90caf9">
          <v-card-actions>
            <v-list-item class="grow px-0">
              <v-list-item-content>
                <v-list-item-title
                  class="custom-form-label me-0"
                  style="font-weight: 600; font-size: 18px"
                >
                  <VAvatar :size="55" rounded color="blue lighten-5" variant="tonal" class="me-3">
                    <v-icon size="34" color="blue darken-4" dark>person</v-icon>
                  </VAvatar>
                  All</v-list-item-title
                >
              </v-list-item-content>
              <div align="center" justify="end">
                <span class="subheading">
                  <v-chip color="#0D47A1" style="font-size: 18px; color: white; font-weight: 600"
                    >{{total}}</v-chip
                  >
                </span>
              </div>
            </v-list-item>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col md="4" class="pr-0">
        <v-card class="mx-auto" style="border: 0.5px solid #ef9a9a">
          <v-card-actions>
            <v-list-item class="grow px-0">
              <v-list-item-content>
                <v-list-item-title
                  class="custom-form-label"
                  style="font-weight: 600; font-size: 16px"
                >
                  <VAvatar :size="55" rounded color="red lighten-5" variant="tonal" class="me-3">
                    <v-icon size="34" color="red" dark>mdi-calendar</v-icon>
                  </VAvatar>
                  This Month</v-list-item-title
                >
              </v-list-item-content>

              <div align="center" justify="end">
                <span class="subheading">
                  <v-chip color="#F44336" style="font-size: 18px; color: white; font-weight: 600"
                    >{{ month }}</v-chip
                  >
                </span>
              </div>
            </v-list-item>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col md="4" class="pr-0">
        <v-card class="mx-auto" style="border: 0.5px solid orange">
          <v-card-actions>
            <v-list-item class="grow px-0">
              <v-list-item-content>
                <v-list-item-title
                  class="custom-form-label"
                  style="font-weight: 600; font-size: 16px"
                >
                  <VAvatar :size="55" rounded color="orange lighten-5" variant="tonal" class="me-3">
                    <v-icon size="34" color="orange" dark>mdi-calendar</v-icon>
                  </VAvatar>
                  Last Month</v-list-item-title
                >
              </v-list-item-content>

              <div align="center" justify="end">
                <span class="subheading">
                  <v-chip color="orange" style="font-size: 18px; color: #fff; font-weight: 600"
                    >{{ lastMonth }}</v-chip
                  ></span
                >
              </div>
            </v-list-item>
          </v-card-actions>
        </v-card>
      </v-col>
      <!-- <v-col md="3">
        <v-card class="mx-auto" style="border: 0.5px solid #81c784">
          <v-card-actions>
            <v-list-item class="grow px-0">
              <v-list-item-content>
                <v-list-item-title
                  class="custom-form-label d-flex align-center"
                  style="font-weight: 600; font-size: 16px"
                >
                  <VAvatar :size="55" rounded color="green lighten-5" variant="tonal" class="me-1">
                    <v-icon size="34" color="green" dark>mdi-calendar</v-icon>
                  </VAvatar>
                  <span
                    >Last 4 Month<br />
                    Average</span
                  >
                </v-list-item-title>
              </v-list-item-content>

              <div align="center" justify="end">
                <span class="subheading"
                  ><v-chip color="green" style="font-size: 18px; color: white; font-weight: 600"
                    >45</v-chip
                  ></span
                >
              </div>
            </v-list-item>
          </v-card-actions>
        </v-card>
      </v-col> -->
    </v-row>
    <v-row>
        
  
        <v-col md="12" class="pl-2" color="blue darken-4 " >
            <v-tabs
                active-class="custom-tab-active"
                v-model="productTab"
                background-color="transparent"
                color="blue darken-4"
                class="custom-tab-transparent tab-sticky"
              >
                <v-tab class="font-size-16 font-weight-600 px-8"  href="#publish"  >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon" >
                 
                  </span>
                  Published
                </v-tab>
                <v-tab
                  class="font-size-16 font-weight-600 px-8"
                  href="#other"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                 
                  </span>
                  Expired
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="productTab">
                <v-tab-item value="publish">
                    <Prmotionlisting    v-on:saveAddress="updateAddressPerson" v-on:month="months" v-on:lastMonth="lastMonths" :status="status"></Prmotionlisting>

                </v-tab-item>
                <v-tab-item value="other">
                    <Expirylisting    v-on:saveAddress="updateAddressPerson"  v-on:month="months" v-on:lastMonth="lastMonths"></Expirylisting>
                </v-tab-item>
              </v-tabs-items>
        </v-col>
      </v-row>
   
  </v-sheet>
</template>
<script>
 import Prmotionlisting from "./Prmotion-Listing.vue";
 import Expirylisting from "./Expiry-Listing.vue";

import {
  SET_SEARCH_TITLE,
  SET_SEARCH_BAR,
  BEFORE_DESTROY,
} from "@/core/services/store/listing.module";
import { mapGetters } from "vuex";
import { format } from "date-fns";
// import { GET_AVAILABLE_VOUCHERS, GET_COLLECTED_VOUCHERS } from "@/core/lib/common.lib";
// import SelectInput from "@/view/components/SelectInput.vue";


export default {
  name: "promotion-listing",
  title: "Listing Promotion",
  data() {
    return {
      text: "Also called a company description, your business description summarizes what your company does, its purpose, and what makes it unique. It's a critical component of your business plan and immediately follows your executive summary",
      dialog: false,
      total:0,
      month:0,
      type:2,
      lastMonth:0,
      status: [
      {
        name:"All",
        color:"blue"
      },
      {
        name:"Active",
        color:"green"
      },
    {name:"InActive",
  color:"red"},
  {name:"Draft",
  color:"cyan"}],
      promotionTableHeading: [
        { title: "ACTION", status: true },
        { title: "IMAGES", status: true },
        { title: "TYPE", status: true },
        { title: "TITLE", status: true },
        { title: "DESCRIPTION", status: true },
        { title: "START DATE", status: true },
        { title: "EXPIRY DATE", status: true },
        { title: "PUBLISH DATE", status: true },
      ],
      promotionTableData: [
        {
          code: "XMH36",
          action: { value: "", key: "ACTION" },
          title: { value: "BUSINESS", key: "TITLE" },
          type: { value: "MEMBERS", key: "TYPE" },
          description: { value: "DESCRIPTION", key: "DESCRIPTION" },
          start_date: { value: "8/08/2023", key: "START DATE" },
          expiry_date: { value: "28/08/2023", key: "END DATE" },
          publish_date: { value: "28/08/2023", key: "PUBLISH DATE" },
          images: {
            value: "https://cdn.pixabay.com/photo/2023/07/04/18/10/wreath-8106795_640.png",
            key: "IMAGES",
          },
        },
        {
          code: "XMQ96",
          action: { value: "", key: "ACTION" },
          title: { value: "CAP STOCKER", key: "TITLE" },
          type: { value: "PREMIUM", key: "TYPE" },
          description: { value: "DESCRIPTION", key: "DESCRIPTION" },
          start_date: { value: "8/08/2023", key: "START DATE" },
          expiry_date: { value: "28/08/2023", key: "END DATE" },
          publish_date: { value: "28/08/2023", key: "PUBLISH DATE" },
          images: {
            value:
              "https://plus.unsplash.com/premium_photo-1661953124283-76d0a8436b87?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8cmVzdGF1cmFudHxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60",
            key: "IMAGES",
          },
        },
      ],
      dragStartIndex: null,
      dragOverIndex: null,
      templateList: [{ text: "First", value: "first" }],
      template: "first",
      whatsapp: {
        files: [],
        template: "first",
      },
      filterType: "all",
      filterDateRange: null,
      speedDial: [],
      whatsappPromotional: false,
      promotions: [],
      currentPage: 1,
      pageLimit: 1,
      showingFrom: 0,
      showingTo: 0,
      totalRows: 0,
      pageLoading: true,
      deleteText: null,
      deleteEndpoint: null,
      deleteDialog: false,
      promotionId: null,
      orderDialog: false,
      rOrderId: 0,
      rOrderCode: null,
      rLoading: false,
      rVouchers: [],
      redeemDialog: false,
      cOrderId: 0,
      cOrderCode: null,
      cLoading: false,
      cVouchers: [],
      collectDialog: false,
      allCollectedDialog: false,
      order: {
        id: null,
        date: format(new Date(), "yyyy-MM-dd"),
        time: format(new Date(), "HH:mm"),
        bill_no: null,
        amount: null,
        voucher: "none",
      },
    };
  },

  methods: {
    updateAddressPerson(param) {
      this.total = param;
    },
    lastMonths(param) {
      this.lastMonth = param;
    },
    months(param) {
      this.month = param;
    },
   

    
    routeToUpdate(id) {
      this.$router.push({
        name: "promotion-update",
        params: { id },
        query: { t: new Date().getTime() },
      });
    },
    routeToDetail(id) {
      this.$router.push({
        name: "promotion-detail",
        params: { id },
        query: { t: new Date().getTime() },
      });
    },

    
   
   
  },
  async mounted() {
    this.$store.dispatch(SET_SEARCH_BAR, true);
    this.$store.dispatch(SET_SEARCH_TITLE, "Promotion");
  },
  beforeDestroy() {
    this.$store.dispatch(BEFORE_DESTROY);
  },
  components: {
    // DeleteTemplate,
    // OrderTemplate,
    // CollectTemplate,
    // RedeemTemplate,
    // SearchTemplate,
    // SearchCriteria,
    // SelectInput,
    // Dialog,
    // DateRangePicker,
    // ValueTemplate,\
    Prmotionlisting,
    Expirylisting
  },
  computed: {
    ...mapGetters(["listingSearch"]),
    productTab: {
        set(val) {
          let query = { ...this.$route.query };
          query.tab = val;
          query.t = new Date().getTime();
          if (val != this.productTab) {
            this.$router.replace({ query });
          }
        },
        get() {
          return this.$route.query.tab || "publish";
        },
      },
  },
};
</script>

<style>
.table_height .v-data-table__wrapper {
  height: calc(100vh - 360px);
}

.promotion-listing-tr:nth-child(even):hover {
  box-shadow: 0px 0px 10px #666666 !important;
  -webkit-box-shadow: 0px 0px 10px #666666 !important;
  -moz-box-shadow: 0px 0px 10px #666666 !important;
  background-color: #e3f2fd !important;
  position: relative;
  z-index: 5;
}

.promotion-listing-tr:nth-child(odd):hover {
  box-shadow: 0px 0px 10px #666666 !important;
  -webkit-box-shadow: 0px 0px 10px #666666 !important;
  -moz-box-shadow: 0px 0px 10px #666666 !important;
}
.custom_field_height v-input__control {
  max-height: 34px;
  height: 34px;
}
.custom_field_height .v-select__selection {
  max-width: 84%;
}
</style>
